<template>
  <svg
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#fff"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.193 26.381a.97.97 0 0 0 1.655-.685V6.302a.97.97 0 0 0-1.94 0v19.394a.97.97 0 0 0 .285.685ZM16 23.756a.97.97 0 0 1-.97-.97V9.212a.97.97 0 0 1 1.94 0v13.575a.97.97 0 0 1-.97.97Zm-7.758-.97a.97.97 0 0 1-.97-.97V10.182a.97.97 0 1 1 1.94 0v11.636a.97.97 0 0 1-.97.97Zm-3.879-2.909a.97.97 0 0 1-.97-.97V13.09a.97.97 0 1 1 1.94 0v5.818a.97.97 0 0 1-.97.97Zm7.072-.284a.97.97 0 0 0 1.655-.685V13.09a.97.97 0 1 0-1.939 0v5.818a.97.97 0 0 0 .284.685Zm12.322 3.194a.97.97 0 0 1-.97-.97V10.18a.97.97 0 0 1 1.94 0v11.636a.97.97 0 0 1-.97.97Zm3.193-4.163a.97.97 0 0 0 1.656-.686V14.06a.97.97 0 0 0-1.94 0v3.88a.97.97 0 0 0 .284.685Z"
    />
  </svg>
</template>

<script>
export default {
}
</script>
