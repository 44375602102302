<template lang="pug">
.episode-pp
  .episode-pp__container
    transition(name="fade")
      .episode-pp__content(
        v-if="currentEpisode"
      )
        UiBreadcrumbs.episode-pp__breadcrumbs(
          :breadcrumbs="episodeBreadcrumbs"
        )

        Episode(
          @onPlayEpisode="playEpisode"
          :podcastData="currentPodcast"
          :episodeData="currentEpisode"
          :episodeCoverUrl="episodeCoverUrl"
          :episodeCoverUrlRetina="episodeCoverUrlRetina"
        )

        nav.episode-pp__menu
          ul.episode-pp__menu-items
            li(
              @click.prevent="setUrlHash('about')"
              :class="{'episode-pp__menu-items-selected': menuSelectedOption=='about'}"
            )
              button {{ $t('single_words.about') }}
            li(
              v-if="renderMenuOption('transcript')"
              @click.prevent="setUrlHash('transcript')"
              :class="{'episode-pp__menu-items-selected': menuSelectedOption=='transcript'}"
            )
              button {{ $t('single_words.transcript') }}
            li(
              v-if="renderMenuOption('chapters')"
              @click.prevent="setUrlHash('chapters')"
              :class="{'episode-pp__menu-items-selected': menuSelectedOption=='chapters'}"
            )
              button {{ $t('single_words.chapters') }}
            li(
              v-if="renderMenuOption('soundbites')"
              @click.prevent="setUrlHash('soundbites')"
              :class="{'episode-pp__menu-items-selected': menuSelectedOption=='soundbites'}"
            )
              button {{ $t('single_words.soundbites') }}

        About(
          v-if="menuSelectedOption=='about'"
          :episode="currentEpisode"
        )

        Transcription(
          v-if="transcription && transcription.length && menuSelectedOption=='transcript'"
          :transcription="transcription"
          :episode="currentEpisode"
          :podcast="currentPodcast"
        )

        Chapters(
          v-if="chapters && chapters.length && menuSelectedOption=='chapters'"
          :chapters="chapters"
          :episode="currentEpisode"
          :podcast="currentPodcast"
        )

        Soundbites(
          v-if="soundbites && soundbites.length && menuSelectedOption=='soundbites'"
          :episode="currentEpisode"
          :podcast="currentPodcast"
          :soundbites="soundbites"
        )

        Navigation

  EmbeddedPlayerModal(
    :podcast="currentPodcast"
    :episode="currentEpisode"
  )
  ShareModal(
    :podcast="currentPodcast"
    :episode="currentEpisode"
  )
  ListenOnModal(
    :podcast="currentPodcast"
  )
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import getMeta from '@/lib/meta'
import { config } from '@/config'
import { noCoverImageName } from '@/lib/vars'
import { htmlentities, convertToDuration } from '@/lib/utils'

import ShareModal from '@/components/modals/share/index'
import ListenOnModal from '@/components/modals/listen-on/index'
import EmbeddedPlayerModal from '@/components/modals/embedded-player/index'

import Episode from '@/components/pages/public-episode/main-content/index'
import About from '@/components/pages/public-episode/about/index'
import Soundbites from '@/components/pages/public-episode/soundbites/index'
import Chapters from '@/components/pages/public-episode/chapters/index'
import Transcription from '@/components/pages/public-episode/transcription/index'
import Navigation from '@/components/pages/public-episode/navigation/index'

export default {
  components: {
    Episode,
    Chapters,
    Navigation,
    About,
    Soundbites,
    Transcription,
    ShareModal,
    ListenOnModal,
    EmbeddedPlayerModal
  },

  layout: 'public-podcast',

  middleware: [
    'languageRedirect'
  ],

  async asyncData (context) {
    const slug = context.route.params.slug.toLowerCase() || ''
    const eid = context.route.params.episode || ''
    const i18nLocale = context.store.state.i18n.locale

    if (!slug || typeof slug !== 'string' || !eid || isNaN(parseInt(eid))) {
      context.error({ statusCode: 404 })
      return
    }

    let episodeCoverUrl = ''
    let episodeCoverUrlRetina = ''

    const currentPodcast = await context.store.dispatch('podcasts/get', slug, { root: true })

    const locale = i18nLocale === 'en' ? '' : `/${i18nLocale}`
    if (!currentPodcast || currentPodcast.redirected) {
      context.redirect(301, `${locale}/`)
      return
    }

    const currentEpisode = await context.store.dispatch('podcasts/getEpisodeById', { slug, eid }, { root: true })

    if (!currentEpisode || (currentEpisode && !currentEpisode.id && !currentEpisode.podcast_id)) {
      context.redirect(301, `${locale}/`)
      return
    }

    const fetchInfoOfChaptersSoundbitesTranscription = Promise.all([
      context.store.dispatch('podcasts/getEpisodeSoundbitesByEid', eid),
      context.store.dispatch('podcasts/getEpisodeChaptersByEid', eid),
      context.store.dispatch('podcasts/getEpisodeTranscriptionByEid', eid)
    ])

    const result = await fetchInfoOfChaptersSoundbitesTranscription

    const soundbites = result[0]
    const chapters = result[1]
    const transcription = result[2]

    const coverImage = currentEpisode.episode_cover ? currentEpisode.episode_cover : ''

    if (coverImage) {
      episodeCoverUrl = `${config.imageResizerUrl}/${slug}/400/${coverImage}`
      episodeCoverUrlRetina = `${config.imageResizerUrl}/${slug}/800/${coverImage}`
    } else {
      const podcastCover = currentPodcast.cover

      if (currentPodcast.cover) {
        episodeCoverUrl = `${config.imageResizerUrl}/${slug}/400/${podcastCover}`
        episodeCoverUrlRetina = `${config.imageResizerUrl}/${slug}/800/${podcastCover}`
      } else {
        episodeCoverUrl = null
        episodeCoverUrlRetina = null
      }
    }

    return {
      chapters,
      soundbites,
      transcription,
      currentPodcast,
      currentEpisode,
      episodeCoverUrl,
      episodeCoverUrlRetina
    }
  },

  data () {
    return {
      menuSelectedOption: 'about'
    }
  },

  head () {
    const meta = {
      route: this.$route,
      type: 'music.radio_station',
      i18nLocales: this.$i18n.locales,
      i18nSeo: this.$nuxtI18nSeo(),
      title: this.$t('meta.publicEpisode.title'),
      options: {}
    }

    let podcastTitle = ''
    let podcastDescription = ''

    let episodeTitle = ''
    let episodeDescription = ''

    if (this.currentPodcast && this.currentEpisode && this.currentEpisode.title) {
      podcastTitle = htmlentities.decode(this.currentPodcast.title)
      podcastDescription = htmlentities.decode(this.currentPodcast.description)
      episodeTitle = htmlentities.decode(this.currentEpisode.title)
      episodeDescription = htmlentities.decode(this.currentEpisode.description)

      meta.title = (
        htmlentities.decode(this.currentPodcast.title + ' - ' + this.currentEpisode.title + ' | RSS.com')
      )

      meta.metaTitle = meta.title
      meta.withoutTitlePostfix = true

      if (episodeDescription) {
        meta.description = episodeDescription.replace(/<[^>]*>?/gm, '')
      } else {
        meta.description = podcastDescription.replace(/<[^>]*>?/gm, '')
      }
    }

    if (this.currentPodcast) {
      meta.quality = this.currentPodcast.q
    }

    if (this.currentPodcast && !this.currentPodcast.s) {
      meta.robotsNoindex = true
    }

    let imageUrl = ''
    if (!imageUrl && this.currentEpisode && this.currentEpisode.episode_cover) {
      imageUrl = this.currentEpisode.episode_cover
    }

    if (!imageUrl && this.currentPodcast && this.currentPodcast.cover) {
      imageUrl = this.currentPodcast.cover
    }

    if (imageUrl) {
      const splittedImageUrl = imageUrl.split('/')
      if (splittedImageUrl.includes(noCoverImageName)) {
        meta.cover = config.baseUrl + '/images/no-cover-1400.png'
        meta.coverSize = [1400, 1400]
      } else {
        const resizedImage = `${config.imageResizerUrl}/${this.podcastSlug}/900/${imageUrl}`
        meta.ogImage = resizedImage
        meta.cover = resizedImage
        meta.twitterOgImage = resizedImage
        meta.coverSize = [900, 900]
      }
      meta.coverAlt = podcastTitle
      meta.options.coverAsFavicon = true
      meta.options.gdpr = this.$store.state.auth.gdprThirdParty
    }

    let episodeDuration = ''
    if (this.currentPodcast && this.currentEpisode && this.currentEpisode['itunes:duration']) {
      episodeDuration = this.currentEpisode.itunes_duratrion
    }

    let podcastAuthor = ''
    if (this.currentPodcast && this.currentPodcast.itunes_author) {
      podcastAuthor = this.currentPodcast.author_name
      meta.author = podcastAuthor
    }

    let episodeMediaUrl = ''
    let episodeMediaType = 'audio/mpeg'
    if (this.currentPodcast && this.currentEpisode && this.currentEpisode.enclosure && this.currentEpisode.enclosure._attributes && this.currentEpisode.enclosure._attributes.url) {
      episodeMediaUrl = this.currentEpisode.enclosure._attributes.url.toLowerCase()
      episodeMediaType = this.currentEpisode.enclosure._attributes.type || 'audio/mpeg'
    }

    let episodeOgAudioTag = ''
    if (this.currentEpisode && this.currentEpisode.episode_asset_url) {
      episodeOgAudioTag = `${this.currentEpisode.episode_asset_url}?_from=ogaudio`
    }

    const resultMeta = getMeta(meta)

    // Noindex, follow meta tag
    const currentLocale = this.$i18n.locale
    let podcastLanguage = this.currentPodcast.language

    if (!podcastLanguage) {
      podcastLanguage = 'en'
    }

    if (podcastLanguage.includes('-')) {
      podcastLanguage = podcastLanguage.split('-')[0]
    }

    const localeCodesAsArray = meta.i18nLocales.map((l) => { return l.code })
    if (currentLocale !== 'en' && !localeCodesAsArray.includes(podcastLanguage)) {
      resultMeta.meta.push({
        hid: 'meta-noindex-follow',
        name: 'robots',
        content: 'noindex, follow'
      })
    }
    if (localeCodesAsArray.includes(podcastLanguage) && currentLocale !== podcastLanguage) {
      resultMeta.meta.push({
        hid: 'meta-noindex-follow',
        name: 'robots',
        content: 'noindex, follow'
      })
    }

    // location
    if (
      this.currentEpisode &&
      this.currentEpisode.location_name &&
      this.currentEpisode.location_coordinates &&
      this.currentEpisode.location_coordinates.coordinates
    ) {
      resultMeta.meta.push({
        hid: 'geo-placename',
        name: 'geo.placename',
        content: this.currentEpisode.location_name
      })
      resultMeta.meta.push({
        hid: 'geo-position',
        name: 'geo.position',
        content: this.currentEpisode.location_coordinates.coordinates.join(';')
      })
    }

    // json+ld data
    const jsonLdInfo = {
      '@context': 'http://schema.org/',
      '@type': 'PodcastEpisode',
      url: this.$locatedLink(`/podcasts/${this.currentPodcast.slug}/episodes/${this.currentEpisode.id}/`),
      name: episodeTitle,
      datePublished: this.currentEpisode.pub_date,
      timeRequired: 'PT' + convertToDuration(episodeDuration),
      description: meta.description,
      associatedMedia: {
        '@type': 'MediaObject',
        contentUrl: episodeMediaUrl
      },
      partOfSeries: {
        '@type': 'PodcastSeries',
        name: podcastTitle,
        url: this.$locatedLink(`/podcasts/${this.currentPodcast.slug}/`)
      }
    }

    resultMeta.script.push({
      type: 'application/ld+json',
      innerHTML: JSON.stringify(jsonLdInfo)
    })

    // Audio OG Meta Tag
    resultMeta.meta.push({
      hid: 'og:audio',
      property: 'og:audio',
      content: episodeOgAudioTag
    })

    // Audio Type OG Meta Tag
    resultMeta.meta.push({
      hid: 'og:audio:type',
      property: 'og:audio:type',
      content: episodeMediaType
    })

    return resultMeta
  },

  computed: {
    ...mapState('podcasts', ['podcasts']),
    ...mapGetters('podcasts', ['podcastByPath']),
    ...mapGetters('episodes', ['podcastEpisodeByEid']),

    podcastSlug () {
      return this.$route.params.slug || ''
    },

    episodeEid () {
      return this.$route.params.episode || ''
    },

    episodeBreadcrumbs () {
      return [
        {
          label: this.currentPodcast.title || '',
          link: this.$locatedLink('/podcasts/' + this.podcastSlug + '/')
        },
        {
          label: this.currentEpisode.title || '',
          current: true
        }
      ]
    }
  },

  mounted () {
    window.addEventListener('hashchange', this.readUrlHash)
    this.readUrlHash()
  },

  beforeDestroy () {
    window.addEventListener('hashchange', this.readUrlHash)
  },

  methods: {
    ...mapActions('player', ['setCurrentPlayingPodcast']),
    ...mapActions('episodes', ['episodeLoadFullInformationByEidAndPid']),

    addEpisode () {
      this.$router.push(this.$route.fullPath + '/new-episode/')
    },

    playEpisode () {
      this.setCurrentPlayingPodcast(this.currentPodcast)
    },

    setUrlHash (tab) {
      if (tab === 'about' && window.location.hash === '') {
        return
      }
      window.location.hash = tab
    },

    readUrlHash () {
      let tab = window.location.hash || ''
      tab = tab.startsWith('#') ? tab.substr(1) : tab // Removes starting # in case it exists
      tab = this.renderMenuOption(tab) ? tab : 'about' // Fix problem not existing tab
      this.menuSelectedOption = tab
    },

    renderMenuOption (tab) {
      switch (tab) {
        case 'transcript':
          return this.transcription && this.transcription.length
        case 'chapters':
          return this.chapters && this.chapters.length
        case 'soundbites':
          return this.soundbites && this.soundbites.length
        case 'about':
          return true
        default:
          return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.episode-pp {
  position: relative;
  padding-top: $gap * 2;
  padding-bottom: $gap * 2;
  background-color: #1C0C32;

  @media screen and (max-width: 1300px) {
    padding-bottom: $gap * 8;
  }

  &__container {
    width: 948px;
    margin: 0 auto;
    position: relative;

    &:before {
      top: 33px;
      z-index: 2;
      content: '';
      left: -212px;
      width: 183px;
      height: 327px;
      display: block;
      position: absolute;
      background-image: url('/images/public-podcast_left.svg');
    }

    @include display-less(desktop) {
      width: 100%;
      padding: 0 $gap;
    }
  }

  &:before {
    right: 0;
    top: -2px;
    z-index: 2;
    content: '';
    width: 1048px;
    height: 632px;
    display: block;
    position: absolute;
    background-image: url('/images/public-podcast_right.svg');
  }

  &__content {
    z-index: 3;
    display: flex;
    position: relative;
    flex-direction: column;
  }

  &__breadcrumbs {
    margin-bottom: $gap * 1.5;
  }

  &__menu {
    overflow-x: scroll;
    width: 100vw;
    margin-left: -$gap;
    -ms-overflow-style: none;  // Hide scrollbar for Edge
    scrollbar-width: none;  // Hide scrollbar for Firefox
    margin-bottom: 24px;
    &-items {
      display: flex;
      gap: $gap;
      margin-left: $gap;
      width: fit-content;
      padding-right: $gap;
      font-size: 20px;
      list-style-type: none;
      > li button {
        color: #fff;
        font-weight: 700;
        background-color: transparent;
        border: none;
        padding-left: 0;
        padding-right: 0;
        text-align: inherit;
        border-radius: 0;
        cursor: pointer;
      }

      &-selected {
        display:inline-block;
        padding-bottom: $gap / 2;
        background: linear-gradient(to right,#f87900, #fe4F45, #e419bA);
        background-size: 100% 4px;
        background-position: bottom 0 left 0, bottom 5px left 0;
        background-repeat: no-repeat;
      }
    }
    &::-webkit-scrollbar {
      display: none; // Hide scrollbar for Chrome
    }
  }
}
</style>
