<template lang="pug">
nav.episodes-nav
  template
    RssUiButton.episodes-nav__prev(
      v-if="previousEpisodeLink"
      :to="previousEpisodeLink"
      type="primary"
      :outlined="true"
    )
      ArrowIcon(
        :color="'#fff'"
      )
      template {{ $t('previous') }} {{ $t('single_words.episode') }}

    RssUiButton.episodes-nav__next(
      v-if="nextEpisodeLink"
      :to="nextEpisodeLink"
      type="primary"
      :outlined="true"
    )
      template {{ $t('next') }} {{ $t('single_words.episode') }}
      ArrowIcon(
        :color="'#fff'"
      )
</template>

<script>
import ArrowIcon from '@/components/icons/dashboard/EpisodeItemArrow.vue'

export default {
  components: {
    ArrowIcon
  },
  data () {
    return {
      prevEpisodeId: null,
      nextEpisodeId: null
    }
  },

  computed: {
    eid () {
      return this.$route.params.episode
    },

    slug () {
      return this.$route.params.slug
    },

    nextEpisodeLink () {
      if (!this.prevEpisodeId) {
        return null
      }

      return this.$locatedLink(`/podcasts/${this.slug}/${this.prevEpisodeId}/`)
    },

    previousEpisodeLink () {
      if (!this.nextEpisodeId) {
        return null
      }

      return this.$locatedLink(`/podcasts/${this.slug}/${this.nextEpisodeId}/`)
    }
  },

  async mounted () {
    const playlist = await this.$store.dispatch('podcasts/getPlaylist', this.slug) || []
    const episodeIndex = playlist.indexOf(Number(this.eid))
    this.prevEpisodeId = episodeIndex - 1 >= 0 ? playlist[episodeIndex - 1] : null
    this.nextEpisodeId = episodeIndex + 1 < playlist.length ? playlist[episodeIndex + 1] : null
  }
}
</script>

<style lang="scss" scoped>
.episodes-nav {
  width: 100%;
  display: flex;
  margin-top: 2 * $gap;
  justify-content: space-between;

  /deep/ .rss-ui-button-wrapper {
    .rss-ui-button {
      color: #fff;
      font-weight: 600;
      border: 1px solid #C2BEC8;

      @include display-less(smart) {
        padding-left: 10px;
        padding-right: 10px;
      }

      &__text {
        height: 100%;
        display: flex;
        align-items: center;

        @include display-less(smart) {
          font-size: 13px;
        }
      }
    }

    &.episodes-nav__prev {
      .rss-ui-button__text {
        svg {
          margin-right: 10px;
          transform: rotate(180deg);
        }
      }
    }

    &.episodes-nav__next {
      .rss-ui-button__text {
        svg {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
