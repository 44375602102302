<template lang="pug">
  section.share
    .share__row
      CopyRssFeedButton.share__copy-rss-feed.share__instance(
        white
      )

      Donation.share__instance(
        :podcastData="podcastData"
      )

      Distribution.share__distribution.share__instance(
        :podcastData="podcastData"
      )

      ShareNetworkElements.share__component.share__instance

      CustomWebsite.share__instance(
        :podcastData="podcastData"
      )
</template>

<script>
import Donation from '@/components/shared/elements/donation/index'
import CustomWebsite from '@/components/shared/elements/custom-website/index'
import Distribution from '@/components/shared/elements/distribution/index'
import CopyRssFeedButton from '@/components/shared/elements/CopyRssFeedButton'
import ShareNetworkElements from '@/components/shared/elements/share-network-elements/index'

export default {
  components: {
    Donation,
    Distribution,
    CustomWebsite,
    CopyRssFeedButton,
    ShareNetworkElements
  },

  props: {
    podcastData: {
      type: Object,
      default: () => {}
    },

    episodeData: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    episodeId () {
      return this.$route.params.episode
    }
  },

  methods: {
    sendSignalToCloseForShare () {
      this.signalToCloseForShare = Math.random()
    },

    sendSignalToCloseForDistribution () {
      this.signalToCloseForDistribution = Math.random()
    }
  }
}
</script>

<style lang="scss" scoped>
.share {
  width: 100%;
  display: flex;
  font-size: 16px;

  &__copy-rss-feed {
    margin-right: $gap * 1.5;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: $gap;

    @include display-less(phablet) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $gap 13px;
    }
  }

  &__instance {
    margin-right: $gap;
    /deep/ a.share-btn {
      margin-right: 0;
    }

    @include display-less(phablet) {
      width: 100%;
    }
  }

  @include display-less(tablet) {
    flex-direction: column;

    .share__row {
      &:not(:last-child) {
        margin-bottom: $gap * 1.5;
      }
    }
  }
}
</style>
