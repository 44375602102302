<template lang="pug">
  section.about
    .about__notes
      h5.about__notes-title {{ $t('episode_notes') }}
      span.about__notes-data-short(
          v-if="!fullDescriptionIsOpened"
          v-html="shortEpisodeDescription"
          ref="shortEpisodeDescription"
        )
      span.about__notes-data-long(
        v-else
        v-html="episodeDescription"
      )
      a.about__notes-see-more(
        href="#"
        v-if="episodeDescription && (shortDescriptionEllipsis || episodeDescription.length > episodeDescriptionMaxLength)"
        @click.prevent="fullDescriptionIsOpened = !fullDescriptionIsOpened"
      )
        template(
          v-if="!fullDescriptionIsOpened"
        ) {{ ' &nbsp;...&nbsp; ' + $t('links.read_more') }}
        template(
          v-else
        ) {{ $t('single_words.hide') }}

    Keywords.about__keywords(
      v-if="episode.episode_keywords && episode.episode_keywords.length"
      :keywords="episode.episode_keywords"
    )

    .about__location(
      v-if="location"
    )
      h5.about__location-title {{ $t('single_words.location') }}
      LocationLink.about__location-data(
        v-if="location"
        :name="episode.location_name"
          :coordinates="episode.location_coordinates.coordinates"
      )
</template>

<script>
import { decode } from 'html-entities'
import stripTags from 'striptags'
import { getMobileOperatingSystem } from '@/lib/utils'

import Keywords from '@/components/pages/public-episode/keywords/index'
import LocationLink from '@/components/elements/location-link/LocationLink.vue'

export default {
  components: {
    Keywords,
    LocationLink
  },

  props: {
    episode: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      mobileOperatingSystem: null,
      fullDescriptionIsOpened: false,
      shortDescriptionEllipsis: false
    }
  },

  computed: {
    episodeDescriptionMaxLength () {
      return !this.mobileOperatingSystem ? 860 : 320
    },

    shortEpisodeDescription () {
      let description = stripTags(this.episode.description, ['p', 'a', 'ul', 'ol', 'li', 'i', 'b', 'strong', 'br', 'em']) || ''
      description = description.toString().replace(/<a /g, '<a rel="noopener nofollow" ')

      if (description && description.length <= this.episodeDescriptionMaxLength) {
        return decode(description)
      }
      const shortDescription = this.closeHtmlTags(description.slice(0, this.episodeDescriptionMaxLength + 1) + '&nbsp;...&nbsp;')
      return decode(shortDescription)
    },

    episodeDescription () {
      const description = stripTags(this.episode.description, ['p', 'a', 'ul', 'ol', 'li', 'i', 'b', 'strong', 'br', 'em']) || ''
      return decode(description.replace(/<a /g, '<a rel="noopener nofollow" target="_blank"'))
    },

    location () {
      if (!this.episode) { return false }

      if (!this.episode.location_name || !this.episode.location_coordinates || !this.episode.location_coordinates.coordinates) {
        return false
      }

      return true
    }
  },

  mounted () {
    this.mobileOperatingSystem = getMobileOperatingSystem()
    this.checkShortDescriptionEllipsis()
    window.addEventListener('resize', this.checkShortDescriptionEllipsis)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.checkShortDescriptionEllipsis)
  },

  methods: {
    closeHtmlTags (text) {
      // Closes open html tags by the slice
      const stack = []
      const regex = /<([^<>]+)>/g

      text = this.fixSlicedTag(text)

      const fullTags = text.match(regex)
      if (fullTags === null) {
        return text
      }
      const tags = fullTags.map(match => match.slice(1, -1).split(' ')[0])

      for (const tag of tags) {
        if (tag[0] === '/') {
          // Close tag
          stack.pop()
        } else if (tag[tag.length - 1] === '/') {
          // Self-closing tag
          continue
        } else {
          // Open tag
          stack.push(tag)
        }
      }

      while (stack.length > 0) {
        const tag = stack.pop()
        text += `</${tag}>`
      }

      return text
    },

    fixSlicedTag (text) {
      // Removes the tag in case it has been sliced.
      for (let i = text.length - 1; i >= 0; i--) {
        if (text[i] === '>') {
          break
        }

        if (text[i] === '<') {
          return text.slice(0, i)
        }
      }
      return text
    },

    checkShortDescriptionEllipsis () {
      const paragraphElement = this.$refs.shortEpisodeDescription
      this.shortDescriptionEllipsis = paragraphElement?.scrollHeight > paragraphElement?.clientHeight
    }
  }
}
</script>

<style lang="scss" scoped>
.about {
  &__notes {
    &-see-more {
      text-decoration: none;
      font-weight: bold;
      display: inline-block;
    }
  }

  &__location {
    &-data {
      font-size: 16px;
    }
  }

  &__notes, &__location {
    color: #ffffff;
    font-size: 16px;

    &-title {
      font-weight: bold;
      margin-bottom: $gap;
      font-size: 16px;
    }

    &-data {
      &-short {
        overflow-wrap: anywhere; // To prevent overflow
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 1px;
      }
      &-long {
        overflow-wrap: anywhere; // To prevent overflow
      }
    }

    /deep/ p {
      margin-bottom: $gap * 0.5;
    }

    /deep/ strong {
      color: #ffffff;
    }

    /deep/ ul {
      color: #ffffff;
      padding-left: $gap;
      li {
        list-style: disc;
      }
    }

    /deep/ ol {
      padding-left: $gap;
      li {
        list-style: decimal;
      }
    }

    /deep/ a {
      color: $colorLightPurple;
      &:hover {
        color: $colorLight;
        background-color: $colorLightPurple;
        text-decoration: none;
      }
    }
  }

  &__notes, &__location, &__keywords {
    margin-bottom: $gap * 2;
  }
}
</style>
