<template lang="pug">
  section.keywords
    h5.keywords__title {{ $t('single_words.keywords') }}
    ListOfKeywords(
      :keywords="keywords"
    )
</template>

<script>
import ListOfKeywords from '@/components/shared/elements/list-of-keywords/index'

export default {
  components: {
    ListOfKeywords
  },

  props: {
    keywords: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.keywords {
  &__title {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: $gap;
  }
}
</style>
