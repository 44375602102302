<template lang="pug">
.keywords
  span.keywords__item(
    v-for="k in keywords"
  ) {{ k.label }}
</template>

<script>
export default {
  props: {
    keywords: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.keywords {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: $gap * 0.5;

  &__item {
    color: #F1EFFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    padding: 8px 12px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.08);
  }
}
</style>
